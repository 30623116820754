<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card>
        <b-button
          v-b-modal.importarPendencia
          variant="primary"
        >
          Importar
        </b-button>
      </b-card>
    </b-overlay>
    <b-modal
      id="importarPendencia"
      ref="importarPendencia"
      title="Pendências em lote"
      ok-title="Pendências em lote"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      :ok-disabled="file === null || descricao === '' || pendenciaSelected === ''"
      @ok="importarPendenciasEmLote"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Selecione o arquivo"
          label-for="name"
        >
          <b-form-file
            v-model="file"
            placeholder=""
            drop-placeholder="Arquivo"
          />
        </b-form-group>
        <label>Pendência</label>
        <v-select
          v-model="pendenciaSelected"
          :options="optionsPendencia"
        />
        <label>Descrição</label>
        <b-form-textarea
          v-model="descricao"
          placeholder="Informe a descrição"
        />
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormFile,
  BFormGroup,
  BCard,
  BOverlay,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '../../../../axios-auth'

export default {
  components: {
    BButton,
    BModal,
    BFormFile,
    BFormGroup,
    BCard,
    BOverlay,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      file: null,
      pendenciaSelected: '',
      optionsPendencia: [],
      load: false,
      descricao: '',
    }
  },
  async created() {
    await this.getMotivoPendencias()
    this.$refs.importarPendencia.show()
  },
  methods: {
    getMotivoPendencias() {
      this.optionsPendencia = []
      axios.get('api/v1/info/motivopendencia')
        .then(res => {
          res.data.dados.map(item => {
            if (item.status) {
              this.optionsPendencia.push({
                id: item.id,
                label: item.nome,
              })
            }
          })
        })
    },
    importarPendenciasEmLote() {
      this.load = true
      const formData = new FormData()
      formData.append('formulario', this.file)
      formData.append('descricao', this.descricao)
      formData.append('motivo_pendencia_id', this.pendenciaSelected.id)
      axios.post('api/v1/operacoes/gerar_pendencia_lote', formData)
        .then(() => {
          this.load = false
          this.file = null
          this.pendenciaSelected = ''
          this.descricao = ''
          this.$swal({
            title: 'Importação realizada com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>
